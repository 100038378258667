import Typography from "@mui/material/Typography";

export default function PriceinfoTextblock(props: any) {
  return (
    <>
      <div className="modalContent">
        <Typography
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1px",
          }}
          variant="body1"
        >
          <div className="priceInfoText">
            <span>{props.infotext}</span>
            {props.additionaltext && (
              <span style={{ lineHeight: 0.2, fontSize: "11px" }}>
                {props.additionaltext}
              </span>
            )}
            <span className="priceInfoWhere">{props.ticketinfo}</span>
          </div>
          <div className="priceValue">
            {props.price ? props.price + "€" : "FREE"}
          </div>
        </Typography>
      </div>
    </>
  );
}
