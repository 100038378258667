import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";

export default function Linerlistitem(props: any) {
  const stationsstring = getstationsstring(props.direction.stations);
  const divider = ": ";
  return (
    <ListItem key={props.index + props.direction.name} disablePadding>
      <ListItemButton
        onClick={() => props.onclick(props.liner.id, props.direction)}
      >
        <div className="mainWrapper">
          <div className="listItemWrapper">
            <ListItemText
              primary={
                <div className="listitemTextWrapper">
                  {props.liner.isshuttle == "1" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 5,
                      }}
                    >
                      <img src="./shuttle.jpeg" width={"auto"} height={15} />
                      <Typography variant="caption">
                        {props.liner.name}
                      </Typography>
                    </div>
                  )}
                  {props.liner.isshuttle != "1" && (
                    <Typography variant="body1">
                      {props.liner.name + divider}
                    </Typography>
                  )}
                  <Typography variant="body1" fontWeight={"bold"}>
                    {props.direction.name}
                  </Typography>
                </div>
              }
            />
            {/* <ListItemText className='stationstring' secondary={stationsstring} /> */}
          </div>
          <ArrowForwardIosIcon />
        </div>
      </ListItemButton>
    </ListItem>
  );
}

function getstationsstring(stations: any) {
  let retval = "Haltestellen: ";
  for (let i = 0; i < stations.length; i++) {
    retval += stations[i].name + " | ";
  }
  return retval;
}
