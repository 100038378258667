import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";

export default function ModalTextblock(props: any) {
  const navigate = useNavigate();

  return (
    <div className="modalContent">
      {props.text && props.text.length > 0 && (
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ fontWeight: "bold" }}
        >
          {props.title}
        </Typography>
      )}
      {props.text &&
        props.text.map((s: any, index: number) => (
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <div className="connectionsWrapper">
              <div>
                <span style={{ fontWeight: "bold" }}>{s.text.linername}</span>
                {" " +
                  s.text.fromtostring +
                  " " +
                  s.text.direction +
                  ": " +
                  s.text.timestring}
              </div>
              <a
                style={{ color: "white" }}
                href={s.url}
                onClick={() => {
                  const direction = {
                    id: s.text.directionid,
                    name: s.directionname,
                  };
                  navigate(s.url, {
                    state: {
                      direction: direction,
                      liner: { id: s.text.linerid, name: s.text.linername },
                    },
                  });
                }}
              >
                <LaunchIcon />
              </a>
            </div>
          </Typography>
        ))}
    </div>
  );
}
