import "./styles.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { CSSProperties, useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import BasicModal from "./Modal/Modal";
import BounceLoader from "react-spinners/BounceLoader";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import PriceModal from "./Modal/PriceModal";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";

export default function Stationlist(props: any) {
  const { linerid, directionid } = useParams();
  const [directiondata, setDirectiondata] = useState<any>([]);
  const [anzcols, setAnzCols] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [modalDeparturesText, setModalDeparturesText] = useState<any>();
  const [modalArrivalsText, setModalArrivalsText] = useState<any>();
  const [stationname, setStationname] = useState<string>();
  const { state } = useLocation() as any;
  const navigate = useNavigate();

  const onPopState = (handler: any) => {
    window.onpopstate = handler;
  };

  useEffect(() => {
    let title = "Nightliner - Fahrplan";

    if (state && state.liner && state.liner.name) {
      title = "Nightliner - Fahrplan - " + state.liner.name;
    }

    if (document.title !== title) document.title = title;
    onPopState((event: any) => {
      setDirectiondata([]);
      if ((event.state || {}).usr) {
        fetchData(
          (event.state || {}).usr.liner.id,
          (event.state || {}).usr.direction.id
        );
      }
    });
    if (linerid && directionid) fetchData(linerid, directionid);
  }, []);

  function fetchData(mylinerid: string, mydirectionid: string) {
    const url =
      `https://verkehrsinfos.it/api/nightliner.php?function=getStationListByDirectionId&linerid=` +
      mylinerid +
      `&directionid=` +
      mydirectionid;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        return response.json();
      })
      .then((actualData) => {
        setDirectiondata(actualData);
        checkNullTimes(actualData);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function checkNullTimes(data: any) {
    let anzTimes = 0;
    data.stations.forEach((station: any) => {
      if (station.times.length > anzTimes) {
        anzTimes = station.times.length;
      }
    });
    let times = [];
    for (let i = 0; i < anzTimes; i++) {
      const arr: any = [];
      times.push(arr);
    }

    for (let j = 0; j < data.stations.length; j++) {
      for (let k = 0; k < data.stations[j].times.length; k++) {
        times[k].push(data.stations[j].times[k].time);
      }
    }

    let anzCols = 0;
    times.forEach((element: any) => {
      if (element.some((t: any) => t)) {
        anzCols++;
      }
    });
    setAnzCols(anzCols);
  }

  const handleOpen = (t: any, stationname: string) => {
    const departurestext = createConnectionText(t.departures, true);
    const arrivalstext = createConnectionText(t.arrivals, false);

    setStationname(stationname);
    setModalDeparturesText(departurestext);
    setModalArrivalsText(arrivalstext);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleClosePriceModal = () => setOpenPriceModal(false);

  function createConnectionText(connections: any, departures: boolean) {
    let rettext = [];
    for (let i = 0; i < connections.length; i++) {
      const text = {
        linerid: connections[i].linerid,
        linername: connections[i].linername,
        fromtostring: departures ? "nach" : "von",
        directionid: connections[i].directionid,
        direction: formatDirection(connections[i].direction, departures),
        timestring: formatTimeValue(connections[i].time),
      };
      const url =
        "/liner/stations/" +
        connections[i].linerid +
        "/" +
        connections[i].directionid;
      rettext.push({
        text: text,
        url: url,
        directionname: connections[i].direction
          ? connections[i].direction
          : "Nightliner",
      });
    }
    return rettext;
  }

  function formatTime(
    time: any,
    timeindex: number,
    stations: any,
    stationindex: number
  ) {
    if (time) {
      return formatTimeValue(time);
    }
    for (let i = stationindex + 1; i < stations.length; i++) {
      if (stations[i].times[timeindex].time !== null) {
        return "|";
      }
    }
    return "";
  }

  function formatDirection(direction: string, departures: boolean) {
    if (departures) {
      const dirArray = direction.split("-");
      return dirArray[dirArray.length - 1].trim();
    }
    return direction.split("-")[0].trim();
  }
  function formatTimeValue(time: string) {
    const t = time.split(":");
    return t[0] + ":" + t[1];
  }
  if (directiondata.length === 0) {
    const override: CSSProperties = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return <BounceLoader cssOverride={override} />;
  }

  function TableRowHeader() {
    return (
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          style={{
            zIndex: 9,
            position: "sticky",
            left: 0,
            backgroundColor: "white",
          }}
        ></TableCell>
        {Array.from({ length: anzcols }).map((_, i) => (
          <TableCell
            key={i}
            component="th"
            scope="row"
            style={{
              verticalAlign: "bottom", // Align content to the bottom of the cell
              textAlign: "left", // Center the content horizontally
              paddingLeft: 25,
              paddingBottom: 3,
            }}
          >
            {getValueRowHeader(i + 1) ? (
              <span className="rectangleHeader" style={{ fontSize: 10 }}>
                {getValueRowHeader(i + 1)}
              </span>
            ) : (
              <></>
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function getValueRowHeader(index: number) {
    const tinfo = directiondata?.timeinfos?.find((t: any) =>
      t.infotimes.split(";").includes(index.toString())
    );
    if (!tinfo || tinfo.length == 0) return "";

    return tinfo.infotype;
  }

  return (
    <>
      <div className="dayInfo">
        {state?.liner?.isshuttle == "1" && (
          <img src="./../../../shuttle.jpeg" width={"auto"} height={20} />
        )}
        <div>{state?.liner?.description}</div>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              <TableRowHeader />
              {directiondata.stations.map((station: any, stationindex: any) => (
                <TableRow
                  key={station.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                    backgroundColor:
                      stationindex % 2 === 0 ? "white" : "#eeeeee",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      zIndex: 9,
                      position: "sticky",
                      left: 0,
                      backgroundColor:
                        stationindex % 2 === 0 ? "white" : "#eeeeee",
                    }}
                  >
                    <div className="stationName">
                      <div>{station.name}</div>
                      <div style={{ fontSize: 10, fontWeight: "bold" }}>
                        {station.onlyexit === true ? "[A]" : ""}
                      </div>
                    </div>
                  </TableCell>
                  {station.times.map(
                    (t: any, timeindex: number) =>
                      (timeindex <= anzcols || anzcols === 0) && (
                        <TableCell className="timeCell" align="center">
                          <div className="timeCellContent">
                            <div>
                              {formatTime(
                                t.time,
                                timeindex,
                                directiondata.stations,
                                stationindex
                              )}
                            </div>

                            {((t.departures && t.departures.length > 0) ||
                              (t.arrivals && t.arrivals.length > 0)) && (
                              <IconButton
                                aria-label="info"
                                size="small"
                                onClick={() => {
                                  handleOpen(t, station.name);
                                }}
                              >
                                <InfoIcon fontSize="inherit" />
                              </IconButton>
                            )}
                          </div>
                        </TableCell>
                      )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <BasicModal
          open={open}
          onClose={handleClose}
          departurestext={modalDeparturesText}
          arrivalstext={modalArrivalsText}
          stationname={stationname}
        />
        <div className="stationListInfoWrapper">
          {directiondata.stations.some((s: any) => s.onlyexit === true) && (
            <p
              style={{ fontSize: 12, marginLeft: "15px", marginRight: "15px" }}
            >
              [A] = hält nur zum Aussteigen
            </p>
          )}
          <div className="stationListBtnWrapper">
            <IconButton
              aria-label="Preisinfo"
              className="stationlistInfoBtn"
              onClick={() => {
                setOpenPriceModal(true);
              }}
            >
              <EuroSymbolIcon />
            </IconButton>
            {directiondata.reversedirectionid && (
              <IconButton
                aria-label="Richtung ändern"
                className="stationlistInfoBtn"
                onClick={() => {
                  setDirectiondata([]);
                  const direction = {
                    id: directiondata.reversedirectionid,
                    name: directiondata.reversedirectionname,
                  };
                  if (linerid && directiondata.reversedirectionid)
                    fetchData(linerid, directiondata.reversedirectionid);
                  navigate(
                    "/liner/stations/" +
                      state.liner.id +
                      "/" +
                      directiondata.reversedirectionid,
                    {
                      state: { direction: direction, liner: state.liner },
                    }
                  );
                }}
              >
                <SyncAltIcon />
              </IconButton>
            )}
          </div>
        </div>
      </Paper>

      {directiondata.timeinfos?.map((element: any, index: any) => (
        <p key={index} className="linerDesc">
          <span className="rectangle">{element.infotype}</span>{" "}
          {element.infotext}
        </p>
      ))}

      <p className="allgInfo">Angaben ohne Gewähr</p>

      <PriceModal open={openPriceModal} onClose={handleClosePriceModal} />
    </>
  );
}
