import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Nightliner suchen",
    to: "/liner",
  },
  {
    name: "Abfahrten",
    to: "/departures",
  },
];
const infoNavItems = [
  {
    name: "Infos",
    to: "/infos",
  },
  {
    name: "Datenschutz",
    to: "/datenschutz",
  },
  {
    name: "Impressum",
    to: "/impressum",
  },
];

export default function DrawerAppBar(props: Props) {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { state } = useLocation() as any;
  let pagetitle = "Nightliner";
  let liner = "";
  let myFontSize = 16;
  if (state) {
    pagetitle = state.direction.name ? state.direction.name : "Nightliner";
    liner = state.liner.name;
    myFontSize = 10;
  }
  if (document.URL.includes("departures")) {
    liner = "Abfahrten";
    pagetitle = "(in den nächsten 2h)";
    myFontSize = 10;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography
        variant="h6"
        sx={{ my: 2 }}
        onClick={() => {
          navigate("/");
        }}
      >
        Nightliner
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              component={Link}
              to={item.to}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        {infoNavItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              component={Link}
              to={item.to}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        position="static"
        style={{ backgroundColor: "#011234" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 0, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src="/logo192.png"
              style={{ width: 35, height: 35, marginRight: "5px" }}
            />
            <div className="headerInfoWrapper">
              {liner && (
                <Typography
                  variant={pagetitle === "Nightliner" ? "h6" : "subtitle1"}
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: { xs: "block", sm: "block" },
                    fontSize: 14,
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  {liner}
                </Typography>
              )}
              <Typography
                variant={pagetitle === "Nightliner" ? "h6" : "subtitle1"}
                component="div"
                sx={{
                  flexGrow: 1,
                  display: { xs: "block", sm: "block" },
                  fontSize: myFontSize,
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                {pagetitle}
              </Typography>
            </div>
          </div>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.name}
                sx={{ color: "#fff" }}
                component={Link}
                to={item.to}
              >
                {item.name}
              </Button>
            ))}
            {infoNavItems.map((item) => (
              <Button
                key={item.name}
                sx={{ color: "#fff" }}
                component={Link}
                to={item.to}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
