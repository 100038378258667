import TextField from "@mui/material/TextField";
export default function Searchfield(props: any) {
  return (
    <div className="search-field">
      <TextField
        fullWidth
        id="standard-basic"
        label={props.label}
        variant="standard"
        onChange={(e) => props.onChangeHandler(e)}
      />
    </div>
  );
}
