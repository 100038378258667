import { CSSProperties, useContext, useEffect, useState } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import "./styles.css";

import Linerlistitem from "./Linerlistitem";
import Searchfield from "./Searchfield";
import { useNavigate } from "react-router-dom";
import { publicIpv4 } from "public-ip";
import BounceLoader from "react-spinners/BounceLoader";

export default function Linerlist(props: any) {
  const navigate = useNavigate();
  const [liner, setLiner] = useState<any>();
  const [allLinerData, setAllData] = useState<any>();
  const [ipadress, setIpAdress] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const title = "Nightliner - Übersicht";
    if (document.title !== title) document.title = title;
    getIp();
    fetch(`https://verkehrsinfos.it/api/nightliner.php?function=getalllines`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        return response.json();
      })
      .then((actualData) => {
        setLiner(actualData);
        setAllData(actualData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  async function insertclickeddirections(directionid: string) {
    fetch(
      "https://verkehrsinfos.it/api/nightliner.php?function=insertclickeddirections",
      {
        method: "POST",
        body: directionid + "#" + ipadress,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  async function getIp() {
    await publicIpv4().then((r) => {
      setIpAdress(r);
    });
  }
  function onClickLiner(linerid: string, direction: any, liner: any) {
    insertclickeddirections(direction.id);
    navigate("/liner/stations/" + linerid + "/" + direction.id, {
      state: { direction: direction, liner: liner },
    });
  }

  function onSearch(e: any) {
    const svalue = e.target.value.toUpperCase().toString();
    if (allLinerData) {
      const myliner = allLinerData.filter(
        (line: any) =>
          line.directions.some(
            (d: any) => d.name.toUpperCase().indexOf(svalue) > -1
          ) ||
          line.name.toUpperCase().indexOf(svalue) > -1 ||
          line.directions.some((d: any) =>
            d.stations.some(
              (s: any) => s.name.toUpperCase().indexOf(svalue) > -1
            )
          )
      );
      setLiner(myliner);
    }
  }

  if (loading) {
    const override: CSSProperties = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return <BounceLoader cssOverride={override} />;
  }
  return (
    <>
      <div
        className="dayInfo"
        style={{ fontSize: "10px", justifyContent: "center" }}
      >
        Gültig Samstag Nacht und 31. Dezember (Ausnahme: N170 (FR)){" "}
      </div>
      <Searchfield
        onChangeHandler={(e: any) => onSearch(e)}
        label="Suche: Linie, Ort, Haltestelle"
      />
      <List>
        {liner &&
          liner.map((liner: any, index: any) => (
            <>
              {liner.directions[0] && (
                <>
                  <Linerlistitem
                    liner={liner}
                    direction={liner.directions[0]}
                    onclick={(linerid: string, direction: any) =>
                      onClickLiner(linerid, direction, liner)
                    }
                  />
                  <Divider />
                </>
              )}
              {liner.directions[1] && (
                <>
                  <Linerlistitem
                    liner={liner}
                    direction={liner.directions[1]}
                    onclick={(linerid: string, direction: any) =>
                      onClickLiner(linerid, direction, liner)
                    }
                  />
                  <Divider />
                </>
              )}
            </>
          ))}
      </List>
    </>
  );
}
function useHistory() {
  throw new Error("Function not implemented.");
}
