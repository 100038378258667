import { Outlet } from "react-router-dom";
import Header from "../components/Header";

const Layout = () => {
  return (
    <>
      <div style={{ position: "sticky", top: 0, zIndex: 20 }}>
        <Header />
      </div>
      <Outlet />
    </>
  );
};
export default Layout;
