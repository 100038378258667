import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ModalTextblock from "./ModalTextblock";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "#011234 !important", //"background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,

  color: "white",
};

export default function BasicModal(props: any) {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          className="modalHeaderWrapper"
          variant="h5"
          component="h2"
        >
          <img
            src="/logo192.png"
            style={{ maxWidth: 30, marginRight: "5px" }}
          />
          <div>{props.stationname}</div>
        </Typography>
        <br />
        {props.departurestext && props.departurestext.length > 0 && (
          <ModalTextblock title="Abfahrten" text={props.departurestext} />
        )}
        {props.arrivalstext && props.arrivalstext.length > 0 && <br />}
        <ModalTextblock title="Ankünfte" text={props.arrivalstext} />
      </Box>
    </Modal>
  );
}
