import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Liner from "./pages/Liner";
import Stationlist from "./components/Stationlist";
import { Datenschutz } from "./components/Datenschutz";
import { Impressum } from "./components/Impressum";
import { Infos } from "./components/Infos";
import { Departures } from "./components/Departures";
import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-ZTXG3ZZWD9");
  }, []);
  return (
    <BrowserRouter>
      <GA4Listener />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="liner" element={<Liner />} />
          <Route
            path="liner/stations/:linerid/:directionid"
            element={<Stationlist />}
          />
          <Route path="departures" element={<Departures />} />
          <Route path="infos" element={<Infos />} />
          <Route path="datenschutz" element={<Datenschutz />} />
          <Route path="impressum" element={<Impressum />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

const GA4Listener = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return null;
};

export default App;
