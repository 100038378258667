import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga4";

function Home() {
  useEffect(() => {
    const title = "Nightliner - Home";
    if (document.title !== title) document.title = title;
  }, []);
  return (
    // <div className='homepageWrapper'>
    // <img src={homeimg} />
    <div className="hero">
      <div className="text">
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
          Kim guat hoam &#128512;
        </Typography>
        <Button
          variant="outlined"
          style={{ color: "white", borderColor: "white" }}
          sx={{ fontSize: 20 }}
          component={Link}
          to="/liner"
        >
          Nightliner suchen
        </Button>
      </div>
    </div>
  );
}

export default Home;
