import { CSSProperties, useEffect, useState } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import "./styles.css";
import TextField from "@mui/material/TextField";

import { useNavigate, Link } from "react-router-dom";

import BounceLoader from "react-spinners/BounceLoader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Autocomplete from "@mui/material/Autocomplete";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export function Departures(props: any) {
  const navigate = useNavigate();
  const [allDepartures, setAllDepartures] = useState<any>();
  const [departures, setDepartures] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  let list: any = [];
  useEffect(() => {
    const title = "Nightliner - Abfahrten";
    if (document.title !== title) document.title = title;
    fetch(
      `https://verkehrsinfos.it/api/nightliner.php?function=getstationdepartures`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        return response.json();
      })
      .then((actualData) => {
        let actualDataMinutes = actualData.filter(
          (ad: any) => ad["minutes"] !== null
        );
        actualDataMinutes.sort((a: any, b: any) =>
          a["minutes"] < b["minutes"] ? -1 : 1
        );
        //setDepartures(actualDataMinutes);
        setAllDepartures(actualDataMinutes);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  function onClickHandler(departure: any) {
    navigate(
      "/liner/stations/" + departure.linerid + "/" + departure.directionid,
      {
        state: {
          direction: {
            id: departure.directionid,
            name: departure.directionname,
          },
          liner: { id: departure.linerid, name: departure.linername },
        },
      }
    );
  }

  function onSearch(e: any, newValue: string | null) {
    if (newValue) {
      const svalue = newValue.toUpperCase().toString();
      if (allDepartures) {
        const mydepartures = allDepartures.filter(
          (departure: any) => departure.stationname.toUpperCase() === svalue
        );
        setDepartures(mydepartures);
      }
    } else {
      setDepartures(undefined);
    }
  }

  if (loading) {
    const override: CSSProperties = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return <BounceLoader cssOverride={override} />;
  }
  if (!loading) {
    allDepartures.forEach((element: any) => {
      if (!list.includes(element.stationname)) {
        list.push(element.stationname);
      }
      list.sort();
    });
  }

  return (
    <>
      <Autocomplete
        className="search-field"
        id="auto-complete"
        autoComplete
        includeInputInList
        options={list ? list : []}
        onChange={(e: any, newValue: any) => onSearch(e, newValue)}
        renderInput={(params: any) => (
          <TextField {...params} label="Haltestellen" variant="standard" />
        )}
      />
      {list.length === 0 && (
        <div className="noDataIconTextWapper">
          <ReportProblemOutlinedIcon
            style={{ fontSize: "124px", color: "#011234" }}
          />
          <p style={{ textAlign: "center" }}>
            Keine Abfahrten in den nächsten zwei Stunden
          </p>
          <Button
            variant="contained"
            style={{ color: "white", backgroundColor: "#011234" }}
            sx={{ fontSize: 20 }}
            component={Link}
            to="/liner"
          >
            Fahrplan suchen
          </Button>
        </div>
      )}
      {!departures && list.length > 0 && (
        <div className="noDataIconTextWapper">
          <ManageSearchIcon
            style={{ fontSize: "124px", color: "#011234" }}
          ></ManageSearchIcon>
          <p>Suche deine Haltestelle!</p>
        </div>
      )}
      {departures && list.length > 0 && (
        <>
          <List>
            {departures &&
              departures.map((departure: any, index: any) => (
                <>
                  <ListItem key={index + departure.stationname} disablePadding>
                    <ListItemButton onClick={() => onClickHandler(departure)}>
                      <div className="mainWrapper">
                        <div className="listItemWrapper">
                          <ListItemText
                            primary={
                              <div
                                className="listitemTextWrapper"
                                style={{ gap: 10 }}
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight={"bold"}
                                  fontSize={18}
                                >
                                  {departure.linername}
                                </Typography>
                                <ArrowRightAltIcon />
                                <Typography variant="body1" fontSize={18}>
                                  {departure.finalstation}
                                </Typography>
                              </div>
                            }
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 5,
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={"bold"}
                            fontSize={23}
                          >
                            {(departure.minutes || departure.minutes === 0) &&
                              departure.minutes + "'"}
                            {!departure.minutes &&
                              departure.minutes !== 0 &&
                              "1h+ "}
                          </Typography>
                          {/* <ArrowForwardIosIcon /> */}
                        </div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              ))}
          </List>
          <p className="allgInfo">Angaben ohne Gewähr</p>
        </>
      )}
    </>
  );
}
