import React from 'react'; 
import Linerlist from '../components/Linerlist';

function Liner() {     
    return (         
        <Linerlist />     
    ); 
} 
    
export default Liner;
