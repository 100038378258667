import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";

export function Infos() {
  useEffect(() => {
    const title = "Nightliner - Infos";
    if (document.title !== title) document.title = title;
  }, []);

  return (
    <Container maxWidth="xl">
      <h1>Nightliner Infos</h1>
      <p>
        Dicht geknüpft und nahezu flächendeckend ist das Netz an
        Busverbindungen, die samstagnachts zur Verfügung stehen. Mit 19
        Hauptlinien sind südtirolweit alle Landesteile, viele Ortschaften und
        beliebte Treffpunkte miteinander verbunden. Ergänzend dazu gibt es ein
        kapillares Netz von insgesamt 33 Shuttle-Diensten, die abgestimmt sind
        auf die Rückfahrten der Nightliner und mit denen Nachtschwärmer in den
        frühen Morgenstunden wieder sicher nach Hause kommen.
      </p>

      <p style={{ fontWeight: "bold", fontSize: "18px" }}>
        Fahrscheine und Tarife
      </p>
      <p>
        Für die Nightliner-Busse und Shuttle-Dienste gelten die folgenden
        Tarife:
      </p>
      <ul>
        <li>
          Einzelfahrt Hauptlinie 3 Euro. Tickets sind in den Bussen sowie online
          erhältlich.
        </li>
        <li>
          Nacht-Ticket für beliebig viele Fahrten mit den Nightlinern
          (Hauptlinien und Shuttle-Dienste): 5 Euro. Tickets sind in den Bussen,
          bei den Fahrscheinautomaten sowie online erhältlich.
        </li>
        <li>
          Einzelfahrt Shuttle-Dienst 2 Euro. Diese Tickets gibt’s nur online im
          südtirolmobil Ticketshop.
        </li>
      </ul>
      <br />
      <p style={{ fontWeight: "bold", fontSize: "18px" }}>
        Fahrgäste mit Südtirol Pass
      </p>
      <p>
        Südtirol Pass, Euregio Family Pass sowie die mit Zahlfunktion
        ausgestatteten Südtirol Pass abo+ und 65+ können in den Nightlinerbussen
        auf den Hauptlinien genutzt werden. Mit einfachem Check-In wird der
        Betrag abgebucht, bei mehreren Fahrten pro Nacht werden max. 5 Euro
        abgebucht.
      </p>
      <p>
        Die gewählte Fahrt – oder auch die gesamte Streck inklusive
        Shuttledienste kann auch vor Fahrtantritt übers Smartphone online
        entwertet werden. Ein Best-Price-Cap von 5 Euro für mehrere Fahrten in
        einer Nacht ist garantiert.
      </p>
      <p>
        Fahrgäste mit Südtirol Pass free können die Nightliner-Busse und
        Shuttledienste mit einfachem Check-In bzw. mittels Vorzeigen beim
        Einsteigen kostenlos nutzen.
      </p>
      <p>
        <b>Bitte beachten:</b> Die Nightliner-Fahrten werden nicht auf dem
        Kilometer-Konto des Südtirol Pass angerechnet. In den Nightlinern ist
        keine Fahrrad- und Tiermitnahme möglich. Die abendlichen Bozner und
        Meraner Stadtlinien N1, N35 und N13 zählen nicht zum Nightliner-Netz,
        die Nightliner-Tickets und Tarife gelten hier daher nicht.
      </p>
      <br />
      <h2>Mobile Ticketing</h2>
      <p>
        Die Tickets für die Nightliner-Busse und Shuttledienste gibt’s jetzt
        auch in digitaler Form im südtirolmobil-Ticketshop. Fahrgäste ohne
        Südtirol Pass und ohne abo+ haben damit bequem die Möglichkeit, übers
        Smartphone jederzeit und ohne vorherige Registrierung die drei
        Fahrschein-Typen für die Nightliner-Dienste zu erwerben und vor Antritt
        der Fahrt online zu entwerten.{" "}
      </p>
      <p style={{ fontWeight: "bold", fontSize: "18px" }}>
        Nightliner-Tickets online kaufen:
      </p>
      <p>
        Im Ticketshop (fliederfarbenes Icon auf der südtirolmobil App) den
        gewünschten Fahrschein auswählen. Den Namen des Fahrschein-Inhabers und
        die gewünschte Anzahl der Fahrscheine angeben. Die Zahlung erfolgt
        mittels Kreditkarte mit Angabe der entsprechenden Daten.
      </p>
      <p style={{ fontWeight: "bold", fontSize: "18px" }}>So wird entwertet:</p>
      <p>
        Nach erfolgtem Kauf befindet sich der digitale Fahrschein in der
        Fahrscheinübersicht („Ticket“-Icon ). Vor Antritt der Fahrt ist das
        Nightliner-Ticket online zu entwerten (Button „Entwerten“ anklicken). Ab
        diesem Moment scheint die entwertete Fahrt als „Aktiv““ auf und die
        Fahrt mit dem Nightliner kann angetreten werden. Beim Nacht-Ticket ist
        nur eine einmalige Entwertung vor der ersten Fahrt erforderlich.
      </p>
      <p>
        {" "}
        Zum Vorzeigen der „aktiven Fahrt“ - beim Einsteigen oder im Falle einer
        Kontrolle – ist das fliederfarbene Icon mit dem Barcode-Design
        anzuklicken und danach der blaue Button „Gültigkeit prüfen“. Einen
        gültigen und entwerteten Nightliner-Fahrschein erkennt man am grünen
        Rahmen sowie an der Bezeichnung „Fahrt aktiv“ samt Angabe der Uhrzeit,
        wann die Entwertung erfolgt ist.
      </p>
      <br />

      <p>
        <a
          href="https://www.suedtirolmobil.info/de/tickets/weitere-tickets/nightliner"
          target="_blank"
        >
          Quelle: www.suedtirolmobil.info
        </a>
      </p>
      <br />
    </Container>
  );
}
